import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { isEqual } from "lodash";

import CustomToolbar from "../custom-toolbar";
import CustomToolbarSelect from "../custom-toolbar-select";
import Loader from "../../loader";
import TravelPlannerActions from "../../travel-planner-actions";

import AppContainer from "../../../container";
import {
  LocalStorageService,
  ILocalStorageService
} from "../../../services/local-storage.service";
import { TagService, ITagService } from "../../../services/tag.service";
import {
  IWorkspaceService,
  WorkspaceService
} from "../../../services/workspace.service";

import UserModel from "../../../models/user.model";
import TagGroupModel from "../../../models/tag-group.model";

import { handleRowClick } from "../table-funcs/helper-funcs";
import { getDisplayDate, ObjectHash } from "../../../utils/helpers";
import {
  customRenderCols,
  disableDownloadCols,
  getCheckmark,
  getIcon,
  getTagChip,
  getPrice,
  getTravelIcon,
  getUserFullName,
  getUsers,
  parseResults,
  unsortableCols
} from "../table-funcs/render-funcs";

export const ServerSideTableWrapper = (
  vState: ObjectHash,
  setState: CallableFunction,
  params: ObjectHash
) => {
  const localStorageService: ILocalStorageService = AppContainer.get(
    LocalStorageService
  );
  const tagService: ITagService = AppContainer.get(TagService);
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );

  const {
    doSearch,
    id,
    inTableFilters,
    inTableSorters,
    searchMeta,
    searchOpts,
    sortBy,
    template
  } = vState;

  const { resource, viewStateKey } = searchOpts;
  const {
    history,
    grid,
    location, // React Router withRouter prop
    onQuickAddTravel,
    openModal,
    renderOpts = {},
    setRowsPerPage, // Persists pagination rowsPerPage
    title,
    settings,
    company
  } = params || {};

  let { results } = vState;

  const useNFLCols = company?.name === "NFL" && resource === "trips";

  let columns = vState.columns || params.columns;
  if (useNFLCols) {
    const newCols = [
      ["Travelers"],
      ["Employee ID", "employeeCode"],
      ["Authorizer", "authorizerName"],
      ["Departure Date", "from"],
      ["Arrival Date", "to"],
      ["Routing"],
      ["Price"],
      ["Confirmation #", "confirmation"],
      ["Departure Airport"],
      ["Arrival Airport"],
      ["Booked Date"],
      ["Invoice Date"],
      ["Airline"],
      ["Duration"],
      ["Departure Timezone"],
      ["Arrival Timezone"],
      ["GDS Locator"]
    ];

    const taggedColumns = columns.filter(
      (c: any[]) => c[0] === "id" || c[0].startsWith("tag-")
    );

    columns = taggedColumns.concat(
      newCols.map((nc) => {
        const match = columns.find(
          (c: any[]) => nc[0] === c[1] || nc[1] === c[0]
        );
        return [match[0], nc[0]];
      })
    );
  }

  let tableTitle = title;

  const colKeys = columns.map((c: any[]) => c[0]);

  // default loads from config
  let colsToDisplay = [...colKeys];

  const userPreset =
    resource === "trips" ? workspaceService.getUserTravelTablePreset() : null;

  // apply user-defined col preset
  if (userPreset?.columns) {
    colsToDisplay = [...userPreset.columns];
  }

  // apply local-storage col preset
  const lsPreset = localStorageService.getViewState(`${viewStateKey}-columns`);
  if (lsPreset) {
    colsToDisplay = [...lsPreset];
  }

  // convert legacy identifier column settings over to tag column ids
  colsToDisplay = tagService.mapIdentifiersColumnIds(
    colsToDisplay,
    settings.tagGroups
  );

  const tableCols = columns.map((column: any[]) => {
    const [fieldName, label] = column;

    const tableCol: ObjectHash = {
      label: label.length ? label : " ",
      name: fieldName,
      options: { sort: true }
    };

    // disable sorting of cols
    if (
      unsortableCols.includes(fieldName) ||
      TagGroupModel.isFieldId(fieldName)
    ) {
      tableCol.options.sort = false;
    }

    // enable display of cols
    if (
      renderOpts.disableViewChange ||
      colsToDisplay.includes(fieldName) ||
      customRenderCols.includes(fieldName)
    ) {
      tableCol.options.display = true;
    } else {
      tableCol.options.display = false;
    }
    // disable csv export of cols
    if (
      disableDownloadCols.includes(fieldName) ||
      !colsToDisplay.includes(fieldName)
    ) {
      tableCol.options.download = false;
    }
    // hide cols from viewColumns menu
    if (customRenderCols.includes(fieldName)) {
      tableCol.options.viewColumns = false;
    }
    // hide id col from display and viewColumns menu on non-admin pages
    if (
      (fieldName === "id" && location.pathname !== "/admin") ||
      fieldName === "userId"
    ) {
      tableCol.options.display = "excluded";
    }
    // use customBodyRender to display React component or special display value in td cell
    if (fieldName && fieldName.toLowerCase().includes("date")) {
      tableCol.options.customBodyRender = (value: string) =>
        getDisplayDate(value, settings.dateFormat);
    }
    if (
      ["arrivalTime", "departureTime", "from", "to"].includes(fieldName) ||
      (fieldName && fieldName.endsWith("DateTime"))
    ) {
      tableCol.options.customBodyRender = (value: string) => {
        value = getDisplayDate(value, settings.dateTimeFormat);
        if (useNFLCols && label?.endsWith(" Date") && value?.includes(" @ ")) {
          return value.split(" @ ")[0];
        }
        return value;
      };
    }
    if (
      [
        "hasAccommodation",
        "hasInbound",
        "hasOutbound",
        "hasTransportation"
      ].includes(fieldName)
    ) {
      tableCol.options.customBodyRender = (value: string) =>
        getTravelIcon(value, fieldName);
    }
    if (fieldName === "icon") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: ObjectHash
      ) => getIcon(tableMeta, colKeys);
    }
    if (["blocked", "primary"].includes(fieldName)) {
      tableCol.options.customBodyRender = (
        value: boolean,
        tableMeta: ObjectHash
      ) => getCheckmark(value);
    }
    if (
      (
        ((template || {}).properties || []).find(
          (p: ObjectHash) => p.name === fieldName
        ) || {}
      ).propertyType === "currency"
    ) {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: ObjectHash
      ) => getPrice(tableMeta, colKeys);
    }
    if (fieldName === "travelPlannerActions") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: ObjectHash
      ) => {
        if (tableMeta.rowData) {
          const userId = tableMeta.rowData[colKeys.indexOf("id")];
          const user = results.find((user: UserModel) => user.id === userId);

          if (!userId || !user) {
            return null;
          }

          return <TravelPlannerActions search={doSearch} user={user} />;
        }
        return null;
      };
    }
    if (fieldName === "users") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: ObjectHash
      ) => getUsers(tableMeta, results, colKeys);
    }

    if (["travelers", "users"].includes(resource) && fieldName === "name") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: ObjectHash
      ) => getUserFullName(value, tableMeta, colKeys, results);
    }

    // Tags as columns
    if (TagGroupModel.isFieldId(fieldName)) {
      tableCol.options.customBodyRender = (
        values: ObjectHash[],
        tableMeta: ObjectHash
      ) => {
        if (!values || !Array.isArray(values)) {
          return "";
        }
        return getTagChip(values);
      };
    }

    return tableCol;
  });

  let tableData: any[] = [];

  if (results) {
    tableData = parseResults(results, colKeys, template);
  }

  let selectableRows = renderOpts.disableRowSelect ? "none" : "multiple";

  let isRowSelectable: CallableFunction = () => true;
  let setRowProps = null;
  if (selectableRows !== "none") {
    setRowProps = (row: string[]) => {
      const rowProps: ObjectHash = {};
      const dataId = row[colKeys.indexOf("id")];
      const data: ObjectHash = results.find(
        (row: ObjectHash) => row.id === dataId
      );

      if (data && workspaceService.dataIsFrozen(data)) {
        rowProps.className = "mui-data-table__row--disabled";
      }

      if (data?.cancelled) {
        rowProps.className = "mui-data-table__row--cancelled";
      }

      return rowProps;
    };

    isRowSelectable = (dataIndex: number) => {
      if (vState.isSoftLoading) {
        return false;
      }

      const dataId = tableData[dataIndex][colKeys.indexOf("id")];
      const data = results.find((row: ObjectHash) => row.id === dataId);

      if (data && workspaceService.dataIsFrozen(data)) {
        return false;
      }

      return true;
    };
  }

  const tableOpts: ObjectHash = {
    customSort: (data: any[]) => data, // disable table sort when server handles it
    customToolbarSelect: () => (
      <CustomToolbarSelect
        displayData={tableData}
        doSearch={doSearch || function () {}}
        idColIndex={colKeys.indexOf("id")}
        onDelete={(pageEmpty: boolean) => {
          // Did we just empty the page of rows by deleting one or more row(s)? paginate back one
          if (pageEmpty) {
            const prevPage = vState.searchSub.queryParams.page - 1;
            if (prevPage > -1) {
              changePagination("page", prevPage);
            }
          }
        }}
        resource={resource}
        results={results}
        selectedRows={vState.selectedRows}
        setState={setState}
        tagId={id}
        tableTitle={title}
        settings={settings}
      />
    ),
    download: !renderOpts.disableDownload,
    downloadOptions: {
      filename: `${title}.csv`
    },
    filter: false,
    filterType: "textField",
    isRowSelectable,
    onDownload: (
      buildHead: CallableFunction,
      buildBody: CallableFunction,
      cols: any[],
      dataRows: any[]
    ) => {
      try {
        const head = buildHead(
          cols.map((c) => ({ ...c, name: c.label || c.name }))
        );

        const tagColumnIndexes: number[] = [];
        cols.forEach((col: ObjectHash, index: number) => {
          if (TagGroupModel.isFieldId(col.name)) {
            tagColumnIndexes.push(index);
          }
        });

        const body = buildBody(
          dataRows.map((row) => ({
            ...row,
            data: row.data.slice().map((d: ObjectHash, index: number) => {
              if (tagColumnIndexes.indexOf(index) !== -1) {
                return d.map((tag: any) => tag.name);
              }
              if (d && d.props && d.props.children) {
                return d.props.children;
              }
              return d;
            })
          }))
        );
        return `${head}${body}`;
      } catch (e) {
        return false;
      }
    },
    onRowSelectionChange: (
      currentRowsSelected: any[],
      allRowsSelected: any[]
    ) => {
      setState({
        selectedRows: allRowsSelected.map((row) => row.dataIndex).sort()
      });
    },
    pagination: !renderOpts.disablePagination,
    print: false,
    responsive: "standard",
    rowsPerPageOptions: [10, 20, 50, 100],
    rowsSelected: vState.selectedRows,
    search: false,
    selectableRows,
    serverSide: true,
    setRowProps,
    sort: !renderOpts.disableSort,
    sortOrder: {},
    textLabels: {
      body: {
        noMatch: ""
      },
      selectedRows: {
        text: "selected"
      },
      toolbar: {
        viewColumns: "Properties"
      }
    },
    viewColumns: !renderOpts.disableViewChange
  };

  tableOpts.customToolbarSelect.displayName = "CustomToolbarSelect";

  if (
    renderOpts.showColorMenu ||
    renderOpts.showQuickAdd ||
    renderOpts.showFilterMenu ||
    renderOpts.showSortMenu ||
    renderOpts.showUserMenu
  ) {
    const displayedCols = tableCols
      .filter((col: ObjectHash) => col.options.display === true)
      .map((col: ObjectHash) => col.name);
    const visibleCols = columns.filter(
      (col: ObjectHash) =>
        col[0] !== "documentRowMenu" && displayedCols.includes(col[0])
    );

    let handleApplyFilter: CallableFunction = function () {};
    let handleApplySort: CallableFunction = function () {};

    tableOpts.customToolbar = () => (
      <CustomToolbar
        data={results}
        dataType={viewStateKey ? viewStateKey.split("-")[1].toUpperCase() : ""}
        grid={grid}
        tableFiltersState={inTableFilters}
        tableSortersState={inTableSorters}
        visibleCols={visibleCols}
        onApplyFilter={handleApplyFilter}
        onApplySort={handleApplySort}
        renderOpts={renderOpts}
        resource={resource}
        tagId={id}
        onQuickAddTravel={onQuickAddTravel}
      />
    );
    tableOpts.customToolbar.displayName = "CustomToolbar";
  }

  if (!renderOpts.disableRowClick) {
    tableOpts.onRowClick = (rowData: any[], rowMeta: ObjectHash) => {
      try {
        const dataId = tableData[rowMeta.dataIndex][colKeys.indexOf("id")];
        if (dataId) {
          const data = results.find((obj: ObjectHash) => obj.id === dataId);
          if (data) {
            if (renderOpts.customRowClick) {
              renderOpts.customRowClick(data);
            } else {
              handleRowClick(data, resource, history, doSearch, [], openModal);
            }
          }
        }
      } catch (e) {
        // ignore error
      }
    };
  }

  if (searchMeta || vState.isSoftLoading) {
    tableTitle = (
      <div className="table-title">
        <span className="table-title__name">{title}</span>
        {searchMeta ? (
          <span className="table-title__results">
            ({" "}
            {!renderOpts.disablePagination &&
              `viewing
              ${
                searchMeta.count > searchMeta.total
                  ? searchMeta.total
                  : searchMeta.count
              } of `}
            {searchMeta.total} )
          </span>
        ) : null}
        {vState.isSoftLoading ? <Loader type="spinner" /> : null}
      </div>
    );

    if (searchMeta) {
      tableOpts.count = searchMeta.total;
      tableOpts.page = searchMeta.page - 1;
      tableOpts.rowsPerPage = searchMeta.limit;
    }

    if (searchOpts.limit) {
      tableOpts.rowsPerPage = searchOpts.limit; // persist rowsPerPage
    }

    if (vState.selectedRows.length) {
      tableOpts.customFooter = () => (
        <TableFooter>
          <TableRow>
            <TableCell />
          </TableRow>
        </TableFooter>
      ); // render empty footer to prevent page changes when rows are selected and serverSide
    } else if (vState.isSoftLoading) {
      tableOpts.customFooter = () => (
        <TableFooter>
          <TableRow>
            <TableCell>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Loader type="spinner" />
              </div>
            </TableCell>
          </TableRow>
        </TableFooter>
      ); // render loader until counts for pagination are available for serverSide
    } else {
      tableOpts.customFooter = null;
    }
  }

  const updateSearchOpts = (searchOpts: ObjectHash) => {
    setState({ searchOpts });
    vState.searchSub.update(searchOpts);
  };

  const changePagination = (queryProp: string, propValue: number): boolean => {
    let searchOpts = { ...vState.searchSub.queryParams };

    // requested change is a no-op
    if (searchOpts[queryProp] === propValue) {
      return false;
    }

    const { viewStateKey } = searchOpts;
    if (viewStateKey && queryProp === "fields") {
      localStorageService.setViewState(`${viewStateKey}-columns`, propValue);
    }

    if (queryProp === "limit") {
      searchOpts.page = 1;
    }

    searchOpts[queryProp] = propValue;

    updateSearchOpts(searchOpts);

    return true;
  };

  tableOpts.onTableChange = (action: string, tableState: ObjectHash) => {
    switch (action) {
      case "changePage":
        changePagination("page", tableState.page + 1);
        break;

      case "changeRowsPerPage":
        const { rowsPerPage } = tableState;
        changePagination("limit", rowsPerPage);
        setRowsPerPage && setRowsPerPage(rowsPerPage);
        break;

      case "propsUpdate":
        const subFilters = vState.searchSub.queryParams?.filters;
        const propFilters = params.searchOpts?.filters;

        if (!isEqual(subFilters, propFilters)) {
          updateSearchOpts(params.searchOpts);
        }
        break;

      case "viewColumnsChange":
        const showColsIndexes: any[] = [];

        tableState.columns
          .map((column: ObjectHash) => column.display)
          .forEach((displayOpt: string, index: number) => {
            if (displayOpt === "true") {
              showColsIndexes.push(index);
            }
          });

        const newFields: any[] = showColsIndexes.map((i) => columns[i][0]);
        localStorageService.setViewState(`${viewStateKey}-columns`, newFields);
        vState.searchOpts.fields = newFields;

        break;

      case "sort":
        const sortByColIndex = tableState.activeColumn;

        if (sortBy.colIndex === sortByColIndex && sortBy.direction === "desc") {
          sortBy.direction = "asc";
        } else if (sortBy.colIndex === sortByColIndex) {
          sortBy.direction = "desc";
        } else {
          sortBy.colIndex = sortByColIndex;
          sortBy.direction = "asc";
        }

        // we assume this will always trigger a search
        const newSearchOpts = {
          ...vState.searchOpts,
          order: colKeys[sortBy.colIndex],
          reverse: sortBy.direction === "desc"
        };

        updateSearchOpts(newSearchOpts);
        break;

      default:
      // do nothing
    }
  };

  const muiDataTableProps = {
    data: tableData,
    title: tableTitle,
    columns: tableCols,
    options: tableOpts
  };

  return {
    muiDataTableProps,
    renderOpts,
    title
  };
};
