import React, { MouseEvent } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import AppIcon from "../../app-icon";
import Button from "../../button";
import RemoveButton from "../../button/remove";
import TagChip from "../../tag-chip";
import Tooltip from "../../tooltip";

import TagGroupModel from "../../../models/tag-group.model";
import TagModel from "../../../models/tag.model";
import UserModel from "../../../models/user.model";

import env from "../../../utils/env";
import { ObjectHash, toPrice } from "../../../utils/helpers";

export const customRenderCols = [
  "activated",
  "blocked",
  "companyId",
  "documentRowMenu",
  "icon",
  "id",
  "primary",
  "travelPlannerActions"
];

export const disableDownloadCols = [
  "",
  "documentRowMenu",
  "icon",
  "id",
  "userId"
];

export const unsortableCols = [
  "activated",
  "blocked",
  "carType",
  "confirmation",
  "flightNum",
  "icon",
  "primary",
  "travelPlannerActions",
  "users"
];

export const getConfirmationForDocument = (
  tableMeta: ObjectHash,
  results: any[],
  colKeys: string[],
  hiddenTrips: any[]
) => {
  if (tableMeta.rowData) {
    const tripId = tableMeta.rowData[colKeys.indexOf("id")];
    const { confirmation } = results.find((obj) => obj.id === tripId) || "";
    let formatClassName = ""; // for doc printMode rowspan/display none to merge cells
    if (!hiddenTrips.includes(tripId)) {
      try {
        const rowConfirmation = results[tableMeta.rowIndex].confirmation || "";
        if (rowConfirmation.length === 0) {
          return;
        }
        // If preceding row is same add display-none class
        if (tableMeta.rowIndex > 0) {
          const prevRowConfirmation =
            results[tableMeta.rowIndex - 1].confirmation || "";
          const prevRowId = results[tableMeta.rowIndex - 1].id;
          if (
            rowConfirmation === prevRowConfirmation &&
            !hiddenTrips.includes(prevRowId)
          ) {
            formatClassName = "table-data--display-none";
          }
        }
        // Check next rows if they are same to add rowspan-[count] class
        if (formatClassName !== "table-data--display-none") {
          let count = 1;
          for (let i = tableMeta.rowIndex; i + 1 < results.length; i += 1) {
            const nextRowConfirmation = results[i + 1].confirmation || "";
            const nextRowId = results[i + 1].id;

            if (
              rowConfirmation === nextRowConfirmation &&
              !hiddenTrips.includes(nextRowId)
            ) {
              count += 1;
            } else {
              break;
            }
          }
          if (count > 1) {
            formatClassName = `table-data--rowspan-${count}`;
          }
        }
      } catch (e) {
        // ignore error
      }
    }
    return (
      <div className={`table-data--confirmation-document ${formatClassName}`}>
        {confirmation}
      </div>
    );
  }
  return null;
};

export const getDocumentRowMenu = (
  tableMeta: ObjectHash,
  colKeys: string[],
  onChangeTableBlockDataOrder: CallableFunction,
  hiddenTrips: any[],
  onChangeTableBlockHiddenTrips: CallableFunction,
  users: any[],
  onChangeTableBlockUsers: CallableFunction,
  staticResults: any[]
) => {
  const objId = tableMeta.rowData[colKeys.indexOf("id")];
  let hiddenTripsButton = null;
  let removeProfileButton = null;
  if (hiddenTrips) {
    hiddenTripsButton = hiddenTrips.includes(objId) ? (
      <Tooltip text="Unhide from view">
        <div
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            onChangeTableBlockHiddenTrips(objId, "unhide");
          }}
        >
          <Button
            color="product-blue"
            icon="visible"
            isRippleDisabled={true}
            isTransparent={true}
            size="medium"
          />
        </div>
      </Tooltip>
    ) : (
      <Tooltip text="Hide from view">
        <div
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            onChangeTableBlockHiddenTrips(objId, "hide");
          }}
        >
          <Button
            color="light-red"
            icon="visible-strike"
            isRippleDisabled={true}
            isTransparent={true}
            size="medium"
          />
        </div>
      </Tooltip>
    );
  }
  if (users) {
    const newUsers = users
      .map((userId) => ({
        label:
          (
            (staticResults || []).find(
              (user: UserModel) => user.id === userId
            ) || {}
          ).name || "",
        value: userId
      }))
      .filter((user) => user.value !== objId);
    removeProfileButton = (
      <Tooltip text="Remove profile">
        <div
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            onChangeTableBlockUsers(newUsers);
          }}
        >
          <RemoveButton size="medium" />
        </div>
      </Tooltip>
    );
  }
  return (
    <div
      className="object-table__document-row-menu"
      onClick={(e) => e.stopPropagation()}
    >
      <Tooltip text="Move up">
        <div
          className="object-table__document-row-menu-button--move-up"
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            onChangeTableBlockDataOrder(objId, "up");
          }}
        >
          <Button
            color="product-blue"
            icon="arrow-up"
            isRippleDisabled={true}
            isTransparent={true}
            size="medium"
          />
        </div>
      </Tooltip>
      <Tooltip text="Move down">
        <div
          className="object-table__document-row-menu-button--move-down"
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            onChangeTableBlockDataOrder(objId, "down");
          }}
        >
          <Button
            color="product-blue"
            isRippleDisabled={true}
            isTransparent={true}
            icon="arrow-down"
            size="medium"
          />
        </div>
      </Tooltip>
      {hiddenTripsButton}
      {removeProfileButton}
    </div>
  );
};

export const getDocumentTableHeader = (
  columns: string[],
  visibleCols: string[],
  columnMeta: ObjectHash,
  onChangeTableBlockColOrder: CallableFunction,
  results: any[],
  template: ObjectHash
) => {
  if (columnMeta.name === "documentRowMenu") {
    return (
      <td
        key={`document-header-cell-${columnMeta.index}`}
        style={{
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          width: "0",
          padding: "0"
        }}
      />
    );
  }

  let headerLabel = columnMeta.label;

  if (
    (
      ((template || {}).properties || []).find(
        (property: ObjectHash) => property.name === columnMeta.name
      ) || {}
    ).propertyType === "currency"
  ) {
    const totalPrice = `$${getSum(results, columnMeta.name)}`;

    headerLabel = (
      <span>
        {columnMeta.label}{" "}
        <span className="price-col-label-wrapper">
          <span className="price-col roboto-mono">{totalPrice}</span>
        </span>
      </span>
    );
  }

  const HeaderComponent = (
    <TableCell
      className="document-header-cell"
      key={`document-header-cell-${columnMeta.index}`}
      style={{
        cursor: "default"
      }}
      variant="head"
    >
      <div className="object-table__document-column-menu">
        <Tooltip key={`move-left-button-${columnMeta.index}`} text="Move left">
          <div
            className="object-table__column-move-button object-table__column-move-button--left"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              onChangeTableBlockColOrder(
                columns,
                visibleCols,
                columnMeta.index,
                "left"
              );
            }}
          >
            <Button
              color="product-blue"
              icon="nav-arrow-left"
              isRippleDisabled={true}
              isTransparent={true}
              size="medium"
            />
          </div>
        </Tooltip>
        <Tooltip
          key={`move-right-button-${columnMeta.index}`}
          text="Move right"
        >
          <div
            className="object-table__column-move-button object-table__column-move-button--right"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              onChangeTableBlockColOrder(
                columns,
                visibleCols,
                columnMeta.index,
                "right"
              );
            }}
          >
            <Button
              color="product-blue"
              icon="nav-arrow-right"
              isRippleDisabled={true}
              isTransparent={true}
              size="medium"
            />
          </div>
        </Tooltip>
      </div>
      <span className="document-header-cell-label">{headerLabel}</span>
    </TableCell>
  );
  return HeaderComponent;
};

export const getCheckmark = (value: boolean) => {
  if (value) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <AppIcon color="green" type="checkmark-circle" size="small" />
      </div>
    );
  }
  return null;
};

export const getIcon = (tableMeta: ObjectHash, colKeys: string[]) => {
  if (tableMeta.rowData) {
    const imageUrl = `url(${
      env.assetUrl + tableMeta.rowData[colKeys.indexOf("icon")]
    })`;
    return (
      <div className="table-data__icon" style={{ backgroundImage: imageUrl }} />
    );
  }
  return null;
};

export const getTagChip = (
  values: ObjectHash[],
  hideUnconfirmedTooltip?: boolean
) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {values.map((value: ObjectHash) => {
        const tag = new TagModel(value);
        return (
          <TagChip
            tag={tag}
            key={tag.id}
            hideUnconfirmedTooltip={Boolean(hideUnconfirmedTooltip)}
          />
        );
      })}
    </div>
  );
};

export const getPrice = (tableMeta: ObjectHash, colKeys: string[]) => {
  if (tableMeta.rowData && tableMeta.columnData) {
    const price = tableMeta.rowData[colKeys.indexOf(tableMeta.columnData.name)];

    return <span className="price-col roboto-mono">{toPrice(price)}</span>;
  }
  return null;
};

const getSum = (results: any[], key: string) =>
  results
    .filter((r) => r[key]) // find trips with a value
    .map((r) => parseFloat(r[key].replace("$", "").replace(",", "") || 0)) // convert to numbers
    .reduce((a, b) => a + b, 0)
    .toFixed(2); // add values

export const getPriceHeader = (
  columnMeta: ObjectHash,
  handleToggleColumn: CallableFunction,
  results: any[]
) => {
  const totalPrice = `$${getSum(results, columnMeta.name)}`;

  const headerLabel = (
    <span>
      {columnMeta.label}{" "}
      <span className="price-col-label-wrapper">
        ( <span className="price-col roboto-mono">{totalPrice}</span> )
      </span>
    </span>
  );

  const HeaderComponent = (
    <TableCell
      key={`price-header-cell-${columnMeta.index}`}
      onClick={() => handleToggleColumn(columnMeta.index)}
      style={{ cursor: "pointer" }}
      variant="head"
    >
      <Tooltip text={"Sort"} placement={"bottom"}>
        <span>{headerLabel}</span>
      </Tooltip>
      {columnMeta.sortDirection !== "none" ? (
        <TableSortLabel
          active={columnMeta.sortDirection !== "none"}
          direction={columnMeta.sortDirection || "asc"}
        />
      ) : null}
    </TableCell>
  );
  return HeaderComponent;
};

export const getPriceForDocument = (
  tableMeta: ObjectHash,
  results: any[],
  colKeys: string[],
  hiddenTrips: any[]
) => {
  if (tableMeta.rowData && tableMeta.columnData) {
    const tripId = tableMeta.rowData[colKeys.indexOf("id")];
    const result = results.find((obj) => obj.id === tripId);
    const price = result[tableMeta.columnData.name] || "";

    // No need to merge CAR/HOTEL in document tables since they are not segmented
    if (["CAR", "HOTEL"].includes(result.type)) {
      return (
        <div className={"price-col roboto-mono table-data--price-document"}>
          {toPrice(price)}
        </div>
      );
    }
    let formatClassName = ""; // for doc printMode rowspan/display none to merge cells
    if (!hiddenTrips.includes(tripId)) {
      try {
        const rowPrice = results[tableMeta.rowIndex].price || "";
        const rowParentTripId = results[tableMeta.rowIndex].tripId || "";
        // Apply display-none class to segments that don't have a price
        if (rowPrice === "") {
          // Check to see if row above of same parent trip has a value if so don't display
          if (tableMeta.rowIndex > 0) {
            for (let i = tableMeta.rowIndex; i > 0; i -= 1) {
              const prevRowParentTripId = results[i - 1].tripId;
              const prevRowId = results[i - 1].id;
              if (
                !hiddenTrips.includes(prevRowId) &&
                rowParentTripId === prevRowParentTripId
              ) {
                const prevRowPrice = results[i - 1].price || "";
                if (prevRowPrice !== "") {
                  formatClassName = "table-data--display-none";
                  break;
                }
              } else {
                break;
              }
            }
          }
        }
        // Check prev and next rows for a row with a price if they are same trip id without value add rowspan-[count] class
        if (rowPrice !== "") {
          let count = 1;
          for (let i = tableMeta.rowIndex; i + 1 < results.length; i += 1) {
            const nextRowParentTripId = results[i + 1].tripId;
            const nextRowId = results[i + 1].id;
            if (
              !hiddenTrips.includes(nextRowId) &&
              rowParentTripId === nextRowParentTripId
            ) {
              const nextRowPrice = results[i + 1].price || "";
              if (nextRowPrice === "") {
                count += 1;
              }
            } else {
              break;
            }
          }
          if (count > 1) {
            formatClassName = `table-data--rowspan-${count}`;
          }
        }
      } catch (e) {
        // ignore error
      }
    }
    return (
      <div
        className={`price-col roboto-mono table-data--price-document ${formatClassName}`}
      >
        {toPrice(price)}
      </div>
    );
  }
  return null;
};

export const getTableClasses = (
  renderOpts: ObjectHash,
  searchOpts: ObjectHash,
  blockState: ObjectHash
) => {
  let tableClasses = ["object-table"];
  // Custom color on document tables
  const tableBlockColor =
    (((blockState || {}).block || {}).options || {}).color || null;
  if (tableBlockColor) {
    tableClasses.push(`table-block-color--${tableBlockColor}`);
  }
  if ((searchOpts || {}).viewStateKey) {
    tableClasses.push(
      `data-type-${searchOpts.viewStateKey.split("-")[1].toLowerCase()}`
    );
  }
  if ((searchOpts || {}).resource) {
    tableClasses.push(`resource-${searchOpts.resource}`);
  }
  if (renderOpts.disableRowClick) {
    tableClasses.push("object-table--unclickable");
  }
  if (renderOpts.disablePagination) {
    tableClasses.push("object-table--no-pagination");
  }
  if (renderOpts.isDocument) {
    tableClasses.push("object-table--document");
  }
  if (renderOpts.useCardStyle) {
    tableClasses.push("object-table--card");
  }
  if (renderOpts.useReportStyle) {
    tableClasses.push("object-table--report");
  }
  if (renderOpts.reportAccentColor) {
    tableClasses.push(`object-table--report-${renderOpts.reportAccentColor}`);
  }
  if (renderOpts.useWarningStyle) {
    tableClasses.push("object-table--warning");
  }
  return tableClasses;
};

export const getTravelIcon = (isBooked: string, key: string) => {
  if (["true", true].includes(isBooked) || ["AIR", "RAIL"].includes(isBooked)) {
    const iconHash: ObjectHash = {
      hasAccommodation: "hotel",
      hasInbound: "flight-land",
      hasOutbound: "flight-takeoff",
      hasTransportation: "car"
    };

    if (isBooked === "RAIL") {
      iconHash.hasInbound = "train";
      iconHash.hasOutbound = "train";
    }

    return <AppIcon color="green" type={iconHash[key]} />;
  }
  return <AppIcon color="gray" type="remove" />;
};

export const getUserFullName = (
  value: string,
  tableMeta: ObjectHash,
  colKeys: string[],
  results: any[]
) => {
  if (!tableMeta.rowData?.length) {
    return value;
  }

  const userId = tableMeta.rowData[colKeys.indexOf("id")];
  const userData: ObjectHash | null = results.find(
    (user: ObjectHash) => user.id === userId
  );
  if (userData?.id) {
    return new UserModel(userData).getFullName();
  }

  return value;
};

export const getUsers = (
  tableMeta: ObjectHash,
  results: any[],
  colKeys: string[]
) => {
  if (!tableMeta.rowData) {
    return null;
  }

  const userTags: any[] = [];
  const tooltipNames: any[] = [];
  const tripId = tableMeta.rowData[colKeys.indexOf("id")];

  try {
    const usersList = results.find((obj) => obj.id === tripId).users;
    usersList.sort((a: ObjectHash, b: ObjectHash) =>
      a.name.localeCompare(b.name)
    );

    usersList.forEach((userData: ObjectHash, index: number) => {
      const userFullName = new UserModel(userData).getFullName();
      if (index < 2) {
        const userTag = (
          <span key={userData.id} className="table-data__user-tag">
            {userFullName}
          </span>
        );
        userTags.push(userTag);
      } else {
        tooltipNames.push(userFullName);
      }
    });

    return (
      <div className="table-data__users">
        {userTags}
        {usersList.length > 2 ? (
          <Tooltip
            text={tooltipNames.map((name, index) => {
              return (
                <p key={`${name}-${index}`} style={{ margin: "0" }}>
                  {name}
                </p>
              );
            })}
            placement="right-start"
          >
            <div className="user-tag__counter">+{usersList.length - 2}</div>
          </Tooltip>
        ) : null}
      </div>
    );
  } catch (e) {
    // ignore error
  }
};

export const getUsersForDocument = (
  tableMeta: ObjectHash,
  results: any[],
  colKeys: string[],
  hiddenTrips: any[]
) => {
  if (tableMeta.rowData) {
    const tripId = tableMeta.rowData[colKeys.indexOf("id")];
    let formatClassName = ""; // for doc printMode rowspan/display none to merge cells
    if (!hiddenTrips.includes(tripId)) {
      try {
        const rowUserIds = results[tableMeta.rowIndex].users.map(
          (u: ObjectHash) => u.id
        );
        if (rowUserIds.length === 0) {
          return;
        }
        // If preceding row is same add display-none class
        if (tableMeta.rowIndex > 0) {
          const prevRowUserIds = results[tableMeta.rowIndex - 1].users.map(
            (u: ObjectHash) => u.id
          );
          const prevRowId = results[tableMeta.rowIndex - 1].id;
          if (
            rowUserIds.every((uId: string) => prevRowUserIds.includes(uId)) &&
            prevRowUserIds.every((uId: string) => rowUserIds.includes(uId)) &&
            !hiddenTrips.includes(prevRowId)
          ) {
            formatClassName = "table-data--display-none";
          }
        }
        // Check next rows if they are same to add rowspan-[count] class
        if (formatClassName !== "table-data--display-none") {
          let count = 1;
          for (let i = tableMeta.rowIndex; i + 1 < results.length; i += 1) {
            const nextRowUserIds = results[i + 1].users.map(
              (u: ObjectHash) => u.id
            );
            const nextRowId = results[i + 1].id;

            if (
              rowUserIds.every((uId: string) => nextRowUserIds.includes(uId)) &&
              nextRowUserIds.every((uId: string) => rowUserIds.includes(uId)) &&
              !hiddenTrips.includes(nextRowId)
            ) {
              count += 1;
            } else {
              break;
            }
          }
          if (count > 1) {
            formatClassName = `table-data--rowspan-${count}`;
          }
        }
      } catch (e) {
        // ignore error
      }
    }
    const displayNames: any[] = [];
    try {
      const usersList = results.find((obj) => obj.id === tripId).users;
      usersList.sort((a: ObjectHash, b: ObjectHash) =>
        a.name.localeCompare(b.name)
      );
      usersList.forEach((user: ObjectHash, index: number) => {
        const displayName = <span key={index}>{user.name}</span>;
        displayNames.push(displayName);
      });
      return (
        <div className={`table-data__users-document ${formatClassName}`}>
          {displayNames}
        </div>
      );
    } catch (e) {
      // ignore error
    }
  }
  return null;
};

export const parseResults = (
  newResults: any[],
  dataKeys: string[],
  template: ObjectHash
) => {
  const currencyProperties = template
    ? template.properties
        .filter((p: ObjectHash) => p.propertyType === "currency")
        .map((p: ObjectHash) => p.name)
    : [];

  return newResults.map((o) =>
    dataKeys.map((key: string) => {
      if (TagGroupModel.isFieldId(key) && o.tags) {
        const groupId = key.split("-").pop();
        return o.tags.filter((tag: TagModel) => tag.tagGroup === groupId);
      }

      if (o[key] === null || o[key] === undefined) {
        return null;
      }

      if (key === "activated") {
        return !o[key] ? "Pending" : `${o.primary ? "" : "Accepted"}`;
      }

      if (currencyProperties.includes(key)) {
        return toPrice(o[key]);
      }

      if (key === "users") {
        return o[key].map((users: ObjectHash) => users.name);
      }

      if (key === "routing") {
        return o[key].replace(/,/g, " > ");
      }

      if (["fromLocation", "toLocation"].includes(key)) {
        if (o[key].indexOf(")") === o[key].length - 1) {
          const parenIndex = o[key].indexOf("(");
          const locationString = `${o[key].substring(
            parenIndex,
            o[key].length
          )} ${o[key].substring(0, parenIndex - 1)}`;
          return locationString;
        }
      }

      return o[key];
    })
  );
};
