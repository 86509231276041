import React from "react";
import Tooltip from "../tooltip";

import { FilterRowParam } from "./";

import ArticleSelect from "./article-select";
import RemoveButton from "../button/remove";
import TravelFilterSelect from "../form/inputs/TravelFilterSelect";

interface Props {
  activeFilterFields?: string[];
  filterRow: FilterRowParam;
  onChange: CallableFunction;
  onRemoveFilter: CallableFunction;
  onSetFilterField: CallableFunction;
  menuPortalTarget?: HTMLElement | null;
}

export default function TravelFilterRow(props: Props) {
  const {
    activeFilterFields = [],
    filterRow,
    menuPortalTarget,
    onChange,
    onRemoveFilter,
    onSetFilterField
  } = props;

  const { model, meta } = filterRow;
  const isDisabled = meta?.readOnly;

  const filterProps = {
    key: filterRow.id,
    menuPortalTarget,
    isDisabled
  };

  return (
    <div className="travel-filter-row">
      <div className="travel-select">
        <TravelFilterSelect
          resource={model.resource}
          defaultValue={model.field}
          disabledFields={activeFilterFields}
          isDisabled={isDisabled}
          menuPortalTarget={menuPortalTarget}
          onChange={(resource: string, field: string) => {
            onSetFilterField(filterRow.id, resource, field);
          }}
        />
      </div>
      <div className="article-select">
        <Tooltip
          text="Coming soon: Is, Is Not, Contains and Does Not Contain"
          placement="top"
        >
          <ArticleSelect
            defaultValue={filterRow.article}
            isDisabled={isDisabled || true}
          />
        </Tooltip>
      </div>
      <div className="filter-input">
        {model.getFilterElement(filterProps, (value: any) => {
          onChange(filterRow.id, value);
        })}
      </div>
      <div className="andor-input">
        <div className="andor-select">
          <Tooltip
            text="Coming soon: And and Or connections between filters"
            placement="top"
          >
            <span>{filterRow.connect}</span>
          </Tooltip>
        </div>
      </div>
      <div className="filter-tools">
        <RemoveButton
          onClick={() => onRemoveFilter(filterRow.id)}
          size="small"
        />
      </div>
    </div>
  );
}
