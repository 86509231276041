import BaseService, { IBaseService } from "./base.service";
import { inject } from "inversify";
import * as _ from "lodash";
import { ApiService, IApiService } from "../services/api.service";
import { ObjectHash } from "../utils/helpers";

export type SearchResource =
  | "airlines"
  | "airports"
  | "documents"
  | "grids"
  | "integrationInstances"
  | "trips"
  | "users";

export interface ISearchService extends IBaseService {
  parseInput(params: any): any;

  parseOutput(
    vState: any,
    data: { results: any[]; meta?: any },
    { fields, resource }: any
  ): ObjectHash[] | null;

  search(resource: SearchResource, params: any, vState?: any): Promise<any[]>;

  getSearchCount(resource: SearchResource, params: any): Promise<number>;
}

export class SearchService extends BaseService implements ISearchService {
  @inject(ApiService)
  apiService!: IApiService;

  parseInput(params: any): any {
    const {
      any = false,
      admin = false,
      gridTagId = null,
      fields = [],
      filters = [],
      limit = 10,
      order = "id",
      page = 0,
      resource,
      reverse = false
    } = params || {};

    if (!resource) return false;

    const data: any = {
      limit,
      order,
      page,
      resource
    };

    if (admin) {
      // if we're in the admin page, open things up
      data.admin = true;
    }

    if (any) {
      data.any = true;
    }

    if (reverse) {
      data.reverse = true;
    }

    if (gridTagId) {
      data.gridTagId = gridTagId;
    }

    let searchFilters = filters
      .filter((f: any) => Array.isArray(f) && f.length === 2)
      .map((f: any) => [
        f[0] === "users.tags" ? f[0] : _.camelCase(f[0]),
        f[1] && !Array.isArray(f[1]) ? `${f[1]}` : f[1]
      ]);

    const searchFields = fields.map((f: any) => _.camelCase(f));
    if (resource === "users") {
      searchFields.push("firstName", "lastName");
    }

    if (["segments", "trips"].includes(resource)) {
      searchFields.push(
        "createdAt",
        "icon",
        "tripId",
        "type",
        "updatedAt",
        "bookedDateTime",
        "from",
        "to"
      );
      if (!data.order || data.order === "id") {
        data.order = "createdAt";
        data.reverse = true;
      }

      if (!searchFilters.find(([k]: any) => ["id", "tripId"].includes(k))) {
        searchFilters.push(["duplicateLink", null]);
      }
    }

    if (["documents"].includes(resource)) {
      searchFields.push("completed");
    }

    searchFilters = searchFilters.filter(
      (f: any) => f[1] === null || (f[1] && f[1].length)
    );
    searchFilters
      .map((f: any) => f[0].replace(/^!/, ""))
      .filter((f: any) => !searchFields.includes(f))
      .forEach((f: any) => searchFields.push(f));

    data.filters = searchFilters;
    data.fields = searchFields;

    return data;
  }

  parseOutput(
    vState: ObjectHash,
    data: { results: any[]; meta?: any },
    { fields, resource }: any
  ): ObjectHash[] {
    if (!Array.isArray(data?.results)) {
      return [];
    }

    vState.results = data.results.map((result) => {
      const r: any = Object.assign({}, result);

      if (
        fields &&
        fields.includes("users") &&
        ["segments", "trips"].includes(resource) &&
        Array.isArray(r.users)
      ) {
        const foundIds: any[] = [];
        r.users = r.users
          .filter((u: any) => u)
          .map((u: any) => {
            const j: any = {};
            if (typeof u === "string") {
              u.split("|").forEach((uk) => {
                const [k, v, ...rest] = (uk || "").split("=");
                if (!rest.length) {
                  j[_.camelCase(k)] = v;
                }
              });
            } else {
              Object.entries(u).forEach(([k, v]) => {
                j[_.camelCase(k)] = v;
              });
            }

            if (foundIds.includes(j.id)) {
              return null;
            }

            foundIds.push(j.id);

            if (j.email && j.email.includes("@example.com")) {
              j.isGhost = true;
            }
            return j;
          })
          .filter((u: any) => u && u.id);
      }

      return r;
    });

    vState.searchMeta = {
      ...((data || {}).meta || {}),
      count: vState.results.length
    };

    vState.isLoading = false;

    return vState.results.slice();
  }

  async search(
    resource: SearchResource,
    params: any,
    vState?: any
  ): Promise<any[]> {
    vState = vState ?? {};

    if (vState.isLoading) {
      return [];
    }
    vState.isLoading = true;

    const parsedParams = this.parseInput({ ...params, resource });

    if (!parsedParams) {
      vState.isLoading = false;
      return [];
    }

    const response = await this.apiService.post("/search", parsedParams);

    if (!response) {
      return [];
    }

    return this.parseOutput(vState, response, parsedParams);
  }

  async getSearchCount(resource: SearchResource, params: any): Promise<number> {
    const parsedParams = this.parseInput({ ...params, resource });
    parsedParams.metaOnly = true;
    parsedParams.fields = [];
    delete parsedParams.limit;
    delete parsedParams.page;

    const response = await this.apiService.post("/search", parsedParams);

    return response?.meta?.total ?? 0;
  }
}
