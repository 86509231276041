import Dexie from "dexie";
import { DateTime } from "luxon";
import { intersection } from "lodash";
import { inject } from "inversify";

import BaseService from "./base.service";
import { ObjectHash } from "../utils/helpers";
import TagModel from "../models/tag.model";
import { AppContextService, IAppContextService } from "./app-context.service";

const CURRENT_VERSION = 2;

export interface IIndexDbService {
  connect(): Promise<boolean>;

  isConnected(): boolean;

  add(store: any, data: any): Promise<any>;

  addMany(store: any, data: any[]): Promise<any>;

  clear(store?: string): Promise<any>;

  clearAllStores(): Promise<any>;

  find(
    store: any,
    opts: {
      filters?: any[];
      limit?: number;
      order?: string;
      page?: number;
      reverse?: boolean;
    },
    metaState: any
  ): Promise<any[]>;

  getStores(): any;

  hasStore(storeKey: string): boolean;
}

export class IndexDbService extends BaseService implements IIndexDbService {
  private database: any = null;
  private connected: boolean = false;
  private stores: any = {
    trips: "++_id, createdAt, deleted, type",
    users: "++_id, createdAt, deleted, isGhost, name"
  };

  @inject(AppContextService)
  private appContextService!: IAppContextService;

  async connect(): Promise<boolean> {
    if (this.connected) {
      return true;
    }

    this.database = new Dexie("TRIPGRID");

    this.database.version(CURRENT_VERSION).stores(this.stores);

    try {
      await this.database.open();
    } catch (err) {
      console.error(err);
      if (err.name === "UpgradeError") {
        this.database.delete().then(() => {
          window.location.reload();
        });
      }
    }

    this.connected = true;

    return true;
  }

  isConnected(): boolean {
    return this.connected;
  }

  async add(store: any, data: any): Promise<any> {
    if (!this.hasStore(store)) {
      return;
    }
    return this.database[store].put({ ...data });
  }

  async addMany(store: any, data: any[] = []): Promise<any> {
    if (!this.hasStore(store)) {
      return;
    }

    return this.database[store].bulkPut(data);
  }

  async clear(store?: string): Promise<any> {
    if (store) {
      return this.database[store].clear();
    }

    return this.database.delete();
  }

  async clearAllStores(): Promise<any> {
    return Promise.all(
      Object.keys(this.stores || {}).map((store) => this.clear(store))
    );
  }

  async find(
    store: any,
    opts: {
      filters?: any[];
      gridTagId?: string;
      limit?: number;
      order?: string;
      page?: number;
      reverse?: boolean;
    },
    metaState: any
  ): Promise<any[]> {
    if (!this.hasStore(store)) {
      return [];
    }

    const searchPreset = store === "trips" ? this._getSearchPreset() : null;

    const defaultOpts = {
      filters: [],
      limit: 10,
      order: "createdAt",
      page: 1,
      reverse: false
    };
    const { filters, gridTagId, limit, order, page, reverse } = {
      ...defaultOpts,
      ...opts
    };

    const validFilters = Array.isArray(filters) ? filters : [];

    // Generate a list of User Ids that match user tag filters
    const userTagFilter = validFilters.find(
      (filter) => filter[0] === "users.tags"
    );
    let taggedUserIds: string[] = [];

    if (userTagFilter) {
      const taggedUsers = await this.database.users
        .filter((user: ObjectHash) => {
          if (!user.tags || !user.tags.length) {
            return false;
          }
          const rowTagIds = user.tags.map((tag: TagModel) => tag.id);

          return userTagFilter[1].every(
            (filterTagIds: string[]) =>
              intersection(filterTagIds, rowTagIds).length > 0
          );
        })
        .toArray();

      if (Array.isArray(taggedUsers)) {
        taggedUserIds = taggedUsers.map((user: ObjectHash) => user.id);
      }
    }

    const filterKeys: any[] = [];
    const filterFuncs = validFilters
      .map(([k, values]: any) => {
        filterKeys.push(k);

        // Type filter MUST match when specified, so it's evaluated separately
        // below to accomodate grid tag filter OR association.
        if (k === "type") {
          return null;
        }

        if (k === "dates") {
          const startTime = Array.isArray(values[0]) ? values[0][0] : values[0];
          const endTime = Array.isArray(values[0]) ? values[0][1] : values[1];

          return (o: any) => {
            const { from, to } = o;

            const fromTime = DateTime.fromISO(from).toMillis();
            if (fromTime >= startTime && fromTime <= endTime) {
              return true;
            }

            const toTime = DateTime.fromISO(to).toMillis();
            if (toTime >= startTime && toTime <= endTime) {
              return true;
            }

            return false;
          };
        }

        if (k === "bookedDateTime") {
          return (o: any) =>
            o[k] &&
            DateTime.fromISO(o[k]).toMillis() >= values[0] &&
            DateTime.fromISO(o[k]).toMillis() <= values[1];
        }

        if (k === "users") {
          return (o: any) =>
            (o.users || []).find((u: any) => values.includes(u.id || u));
        }

        if (k === "cancelled") {
          return (o: any) => o.cancelled || !o.deleted;
        }

        if (k === "tags") {
          return (row: any) => {
            if (!row.tags || !row.tags.length) {
              return false;
            }
            const rowTagIds = row.tags.map((tag: TagModel) => tag.id);

            return values.every(
              (filterTagIds: string) =>
                intersection(filterTagIds, rowTagIds).length > 0
            );
          };
        }

        if (k === "users.tags") {
          return (row: any) => {
            if (
              !Array.isArray(row.users) ||
              !row.users.length ||
              !taggedUserIds.length
            ) {
              return false;
            }
            const userIds: string[] = row.users.map((user: ObjectHash) =>
              typeof user === "string" ? user : user.id
            );
            return intersection(taggedUserIds, userIds).length > 0;
          };
        }

        /*
         * The to/fromLocation field is shared between different trip types (i.e. air, rail, transportation) but is
         * currently being used for a travel filter labeled "Departure Airport", apparently implying that when
         * the fitler is specified, only flights should be returned in search results. Airports of course frequently
         * have rental car places (transportation) and even rail stations, but we are not currently supporting
         * filtering based on non-flight departure locations.
         */
        if (["toLocation", "fromLocation"].includes(k)) {
          return (o: any) => o.type === "AIR" && values.includes(o[k]);
        }

        if (Array.isArray(values)) {
          return (o: any) => values.includes(o[k]);
        }

        return (o: any) =>
          !values ||
          o[k] === values ||
          (o[k] && values && o[k].toLowerCase().includes(values.toLowerCase()));
      })
      .filter((filterFunc: CallableFunction | null) => filterFunc)
      .reduce((a: any, b: any) => a.concat(b), []);

    const showCancelled = filterKeys.includes("cancelled");

    // see the below comment regarding the grid tag id
    const travelTypeFilter = validFilters.find(
      (filter: any[]) => filter[0] === "type"
    );
    const validTravelTypes = travelTypeFilter ? travelTypeFilter[1] : null;

    const allResults = await this.database[store]
      .orderBy(this.stores[store].includes(order) ? order : "createdAt")
      .filter((result: ObjectHash) => {
        if (!result) {
          return false;
        }

        const validStatus =
          !result.deleted || (showCancelled && result.cancelled);

        if (!validStatus) {
          return false;
        }

        if (result.isGhost) {
          return false;
        }

        // When travel type is specified, it MUST match regardless of other filters
        const travelTypeMatches =
          !validTravelTypes ||
          validTravelTypes.includes(String(result.type).toUpperCase());
        if (!travelTypeMatches) {
          return false;
        }

        // exclude travel which matches the search preset excluded tags list
        if (Array.isArray(searchPreset?.exclude?.tags)) {
          const matchingTag = result.tags.find((tag: TagModel) =>
            searchPreset?.exclude.tags.includes(tag.id)
          );

          if (matchingTag) {
            return false;
          }
        }

        // only include travel which matches the search preset included tags list
        if (Array.isArray(searchPreset?.include)) {
          for (const includePreset of (searchPreset?.include as ObjectHash[])) {
            if (Array.isArray(includePreset.tags)) {
              const matchingTag = result.tags.some((tag: TagModel) =>
                includePreset?.tags.includes(tag.id)
              );

              if (!matchingTag) {
                return false;
              }              
            }
          }
        }

        /*
         * @todo The grid tag id is a hard-coded filter used by v1 of Grids, with an OR association, allowing
         * any trip with the grid tag to be included in the grid. Later versions of the search API will support
         * AND/OR associations on any filters, and the association type will become part of the filter data.
         */
        if (gridTagId) {
          let matchingTag;

          if (Array.isArray(result.tags)) {
            matchingTag = result.tags.find(
              (tag: TagModel) => tag.id === gridTagId
            );
          }

          if (matchingTag) {
            return true;
          }

          if (!filterFuncs.length) {
            return false;
          }
        }

        if (!filterFuncs.length) {
          return true;
        }

        return filterFuncs.every((func: Function) => func({ ...result }));
      })
      .toArray();

    if (!this.stores[store].includes(order)) {
      const numberFields = ["price"];
      const sortAs = numberFields.includes(order) ? "number" : "string";
      const getNumberVal = (str: any) =>
        Number(String(str).replace(/[^0-9\.]+/g, "")) || 0;

      allResults.sort((a: any, b: any) => {
        let v1 = a[order];
        let v2 = b[order];

        switch (sortAs) {
          case "number":
            v1 = getNumberVal(v1);
            v2 = getNumberVal(v2);
            break;
          case "string":
            v1 = String(v1).toLowerCase();
            v2 = String(v2).toLowerCase();
            break;
        }

        if (v1 > v2) {
          return reverse ? -1 : 1;
        }
        if (v2 > v1) {
          return reverse ? 1 : -1;
        }
        return 0;
      }, []);
    } else if (reverse) {
      allResults.reverse();
    }

    const offset = (page - 1) * limit;
    const slicedResults = allResults.slice(offset, offset + limit);

    if (metaState) {
      metaState.searchMeta = {
        count: slicedResults.length,
        limit,
        page,
        total: allResults.length
      };
    }

    return slicedResults;
  }

  public hasStore(storeKey: string): boolean {
    return Boolean(this.stores[storeKey]);
  }

  public getStores(): any {
    return this.stores;
  }

  private _getSearchPreset(): ObjectHash | null {
    const { settings, user } = this.appContextService.get();

    if (!settings?.searchPresets) {
      return null;
    }

    const presets = settings.searchPresets;
    let preset: any = null;

    if (user?.searchPreset) {
      preset = presets[user.searchPreset];
    } else if (presets.default) {
      preset = presets.default;
    }

    return preset || null;
  }
}
