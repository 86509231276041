import React from "react";
import Select from "react-select";
import AppIcon from "../../components/app-icon";
import { ObjectHash } from "../../utils/helpers";
import { customStyle } from "../form/inputs/custom-theme";

interface Props {
  defaultValue: "are" | "is" | "is not";
  isDisabled?: boolean;
}

const articleOpts = [
  { label: "Is", value: "is" },
  { label: "Are", value: "are" }
];

export default function ArticleSelect(props: Props) {
  const { defaultValue, isDisabled } = props;

  const { menuPortal } = customStyle;

  const optionStyles = {
    container: (styles: ObjectHash) => ({
      ...styles,
      width: "100%"
    }),
    control: (styles: ObjectHash) => ({
      ...styles,
      borderWidth: "2px",
      paddingRight: "8px",
      height: 40,
      width: "100%"
    }),
    menuPortal
  };

  return (
    <Select
      options={articleOpts}
      components={{
        DropdownIndicator: () => <AppIcon type="menu-arrow" />,
        IndicatorSeparator: () => null
      }}
      defaultValue={articleOpts.find(
        (opt: ObjectHash) => opt.value === defaultValue
      )}
      isDisabled={isDisabled || true}
      styles={optionStyles}
    />
  );
}
