import { resolve } from "inversify-react";
import React from "react";
import { assign, cloneDeep, uniqueId } from "lodash";

import {
  ITravelQueryService,
  TravelQueryService
} from "../../services/travel-query.service";

import GridModel from "../../models/grid.model";
import TravelFilterModel, {
  TravelFilterFields,
  TravelFilterGroups,
  TravelFilterResources
} from "../../models/travel-filter.model";
import TravelQueryModel, {
  TravelQueryParam
} from "../../models/travel-query.model";

import { ObjectHash, sortByPosition } from "../../utils/helpers";

import TravelFilterRow from "./travel-filter-row";

import "./travel-filter.scss";

export class FilterRowParam {
  id: string;
  article: "is" | "is not";
  connect: "and" | "or";
  model: TravelFilterModel;
  meta?: ObjectHash;
  rowIndex?: number;

  constructor(data: ObjectHash) {
    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.article = data.article;
    this.connect = data.connect;
    this.model = data.model;

    if (data.meta) {
      this.meta = data.meta;
    }
  }

  hasGridTagGroup(): boolean {
    const { model } = this;

    if (
      model.group !== TravelFilterGroups.Tag ||
      model.resource !== TravelFilterResources.Trip
    ) {
      return false;
    }

    return Boolean(model.meta?.tagGroup?.isGridTagGroup());
  }
}

export interface Props {
  onChange: (travelQuery: TravelQueryModel) => void;
  openModal: CallableFunction;
  menuPortalTarget?: HTMLElement | null;
  onFilterRow?: CallableFunction;
  travelQuery?: TravelQueryModel;
  grid?: GridModel;
}

interface State {
  filterRows: FilterRowParam[];
}

export default class TravelFilter extends React.Component<Props, State> {
  @resolve(TravelQueryService)
  private travelQueryService!: ITravelQueryService;

  constructor(props: Props) {
    super(props);

    this.state = {
      filterRows: []
    };
  }

  async componentDidMount() {
    const filterRows = await this.loadFromTravelQuery();
    this.setState({ filterRows });
  }

  setFilterField(
    rowId: string,
    resource: TravelFilterResources,
    field: TravelFilterFields
  ) {
    const { filterRows } = this.state;
    const filterRow = filterRows.find(
      (filterRow: FilterRowParam) => filterRow.id === rowId
    );
    if (!filterRow) {
      return;
    }

    const filterModel = this.travelQueryService.getFilterByField(
      resource,
      field
    );
    if (!filterModel) {
      return;
    }

    filterRow.model = filterModel;
    filterRow.id = uniqueId("filter-row-");

    this.updateFilterRow(filterRow);
  }

  addFilter = (resource: TravelFilterResources) => {
    this.updateFilterRow(
      this.getNewFilterRow(new TravelFilterModel({ resource }))
    );
  };

  handleChange = (rowId: string, value: any) => {
    const { filterRows } = this.state;
    const filterRow = filterRows.find(
      (filterRow: FilterRowParam) => filterRow.id === rowId
    );
    if (!filterRow) {
      return;
    }

    filterRow.model.value = value;
    this.updateFilterRow(filterRow);
  };

  handleRemoveFilter = (rowId: string) => {
    const { filterRows } = this.state;
    const { onChange, openModal, grid } = this.props;

    const rowIndex = filterRows.findIndex(
      (filterRow: FilterRowParam) => filterRow.id === rowId
    );
    if (rowIndex === -1) {
      return;
    }

    const spliceFilterRows = () => {
      filterRows.splice(rowIndex, 1);
      this.setState({ filterRows }, () => {
        onChange(this.getTravelQuery());
      });
    };

    const removeFilterGridCheck =
      grid &&
      this.travelQueryService.gridHasStandardQuery(grid) &&
      this.isRemovingLastGridTag(filterRows[rowIndex]);

    if (removeFilterGridCheck) {
      openModal("confirm", {
        dialogTitle: `Remove Grid Tag Filter`,
        buttonText: "Yes, remove the filter",
        dialogBody: `Are you sure you want to remove the Grid Tag filter from this grid? If you remove this filter, e-mail forwarding and other features of the Grid may stop working.`,
        onConfirm: spliceFilterRows
      });

      return;
    }

    spliceFilterRows();
  };

  /*
   * When editing a travel query related to a grid, we must check if the user is attempting
   * to remove a row which contains the grid tag related to the grid. If they are, and they
   * are removing the ONLY occurence of that grid tag from the filter, show a warning. Grids
   * with filter sets that don't include their own grid tag don't work that well.
   */
  isRemovingLastGridTag = (targetRow: FilterRowParam) => {
    const { grid } = this.props;
    const { filterRows } = this.state;

    const tripFilters = filterRows.filter(
      (filterRow: FilterRowParam) =>
        filterRow.model.resource === TravelFilterResources.Trip
    );

    let removingGridTagRow: boolean = false;

    const gridTagFilterRows = tripFilters.filter(
      (filterRow: FilterRowParam) => {
        const { model } = filterRow;

        if (!filterRow.hasGridTagGroup()) {
          return false;
        }

        if (
          !model.value.includes("new-grid-tag") &&
          !model.value.includes(grid?.tag.id)
        ) {
          return false;
        }

        // this row is a grid tag row, and it's the one being removed
        if (filterRow.id === targetRow.id) {
          removingGridTagRow = true;
        }

        return true;
      }
    );

    // either there is no current grid tag row, or there is more than one, so we cannot be removing the last one
    if (gridTagFilterRows.length !== 1) {
      return false;
    }

    return removingGridTagRow;
  };

  async loadFromTravelQuery(): Promise<FilterRowParam[]> {
    const { travelQuery, grid } = this.props;

    const sourceQuery = grid ? grid.query : travelQuery;

    if (!sourceQuery?.params.length) {
      return [];
    }

    const filterRows: FilterRowParam[] = [];

    const queryMeta = await this.travelQueryService.getQueryMeta(sourceQuery);

    sourceQuery.params
      .sort(sortByPosition)
      .forEach((param: TravelQueryParam) => {
        const { field, resource } = param;
        const filterModel: TravelFilterModel | null = this.travelQueryService.getFilterByField(
          resource,
          field
        );

        if (!filterModel) {
          return;
        }

        filterModel.value = param.value;
        filterModel.meta = assign(filterModel.meta, queryMeta);

        let filterRow = this.getNewFilterRow(filterModel);

        filterRow.article = param.article;
        filterRow.connect = param.connect;
        filterRow.meta = param.meta;

        filterRows.push(filterRow);
      });

    return filterRows;
  }

  getTravelQuery() {
    const { travelQuery } = this.props;
    const { filterRows } = this.state;

    let paramPosition = 1;
    const newTravelQuery = new TravelQueryModel(
      travelQuery ? cloneDeep(travelQuery) : {}
    );

    newTravelQuery.params = filterRows
      .filter((row: FilterRowParam) => row.model?.field && row.model?.value)
      .map((row: FilterRowParam) => {
        const { model } = row;
        return new TravelQueryParam({
          ...model,
          position: paramPosition++,
          article: row.article,
          connect: row.connect,
          meta: row.meta ?? {}
        });
      });

    return newTravelQuery;
  }

  updateFilterRow(updateRow: FilterRowParam) {
    const { onChange } = this.props;
    const { filterRows } = this.state;

    const rowIndex = filterRows.findIndex(
      (filterRow: FilterRowParam) => filterRow.id === updateRow.id
    );

    if (rowIndex === -1) {
      filterRows.push(updateRow);
    } else {
      filterRows[rowIndex] = updateRow;
    }

    this.setState({ filterRows }, async () => {
      const updatedQuery = this.getTravelQuery();
      onChange(updatedQuery);
    });
  }

  getNewFilterRow(model: TravelFilterModel): FilterRowParam {
    return new FilterRowParam({
      id: uniqueId("filter-row-"),
      model: model,
      article: "is",
      connect: "and"
    });
  }

  render() {
    const { grid, menuPortalTarget, onFilterRow } = this.props;
    let { filterRows } = this.state;

    if (onFilterRow) {
      filterRows = filterRows.map((filterRow: FilterRowParam, rowIndex) =>
        onFilterRow(filterRow, rowIndex)
      );
    }

    const travelerFilterRows = filterRows.filter(
      (filterRow: FilterRowParam) =>
        filterRow.model.resource === TravelFilterResources.Trip
    );

    const travelerFilterFields = travelerFilterRows.map(
      (filterRow: FilterRowParam) => filterRow.model.field
    );

    const userFilterRows = filterRows.filter(
      (filterRow: FilterRowParam) =>
        filterRow.model.resource === TravelFilterResources.User
    );

    return (
      <div className="travel-filter">
        <div className="travel-filter-type">
          <div className="header">Travel Filters</div>
          {travelerFilterRows.map((filterRow) => {
            const { id, meta, model } = filterRow;
            return (
              <TravelFilterRow
                activeFilterFields={travelerFilterFields.filter(
                  (field: string) => field !== model.field
                )}
                key={`${id}${
                  meta?.systemGridParam
                    ? `-${grid?.tag.name}-${grid?.tag.color}` // re-renders the filter row with grid tag when editing the name/color
                    : ""
                }`}
                filterRow={filterRow}
                onChange={(rowId: string, value: any) =>
                  this.handleChange(rowId, value)
                }
                onSetFilterField={(
                  rowId: string,
                  resource: TravelFilterResources,
                  field: TravelFilterFields
                ) => this.setFilterField(rowId, resource, field)}
                onRemoveFilter={(rowId: string) => {
                  this.handleRemoveFilter(rowId);
                }}
                menuPortalTarget={menuPortalTarget}
              />
            );
          })}
          <div className="travel-filter-add-row">
            <span onClick={() => this.addFilter(TravelFilterResources.Trip)}>
              Add Filter
            </span>
          </div>
        </div>

        <div className="travel-filter-type">
          <div className="header">Profile Filters</div>
          {userFilterRows.map((filterRow) => {
            return (
              <TravelFilterRow
                key={filterRow.id}
                filterRow={filterRow}
                onChange={(rowId: string, value: any) =>
                  this.handleChange(rowId, value)
                }
                onSetFilterField={(
                  rowId: string,
                  resource: TravelFilterResources,
                  field: TravelFilterFields
                ) => this.setFilterField(rowId, resource, field)}
                onRemoveFilter={(rowId: string) => {
                  this.handleRemoveFilter(rowId);
                }}
                menuPortalTarget={menuPortalTarget}
              />
            );
          })}
          <div className="travel-filter-add-row">
            <span onClick={() => this.addFilter(TravelFilterResources.User)}>
              Add Filter
            </span>
          </div>
        </div>
      </div>
    );
  }
}
